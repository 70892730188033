.page-results
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    //margin-top: 160px
    color: #4D4D4D
    .header
        height: 100px
        margin-bottom: 40px
        background: #FFFFFF
        padding: 0
        +tab
            margin-bottom: 75px
        +mob
            height: 50px
            margin-bottom: 20px
        .logo
            height: 40px
            position: relative
            top: initial
            left: initial
            +tab
                height: 32.7px
            +mob
                height: 25.5px
    .block
        display: none
    .sections
        width: 100%
        max-width: 1136px
        padding: 0
        +tab
            padding: 0 36px
        +mob
            padding: 0 24px
    .section
        position: relative
        width: 100%
        background: #FFFFFF
        border-radius: 32px
        padding: 40px
        margin-bottom: 40px
        +mob
            padding: 24px
            border-radius: 24px
            margin-bottom: 32px
        .title
            position: relative
            font-size: 32px
            font-weight: 600
            line-height: 40px
            color: #4D4D4D
            z-index: 1
            +tab
                font-size: 24px
                line-height: 32px
            +mob
                font-size: 20px
                font-weight: 700
                line-height: 32px
                //margin-bottom: 16px
        >.title
            color: #4D4D4D
        .descr
            position: relative
            font-size: 16px
            font-weight: 400
            line-height: 32px
            color: #2A2A2B
            white-space: pre-line
            z-index: 1
            +tab
                font-size: 14px
                line-height: 24px
            +mob
                font-size: 12px
                line-height: 16px
                color: #757575
        .button
            margin-top: 40px
            position: relative
            z-index: 1
            &:not(:last-child)
                margin-right: 8px
            +tab
                font-size: 16px

            +mob
                margin-top: 24px
                margin-right: 0
                width: 100%
        .sep
            position: relative
            width: 100%
            height: 1px
            background-color: #F3F3F4
            margin-top: 24px
            margin-bottom: 24px

        &.cover
            height: 192px
            background-size: cover
            border-radius: 0 0 32px 32px
            display: flex
            justify-content: center
            align-items: center
            margin-bottom: 56px
            +tab
                height: 170px
                margin-bottom: 40px
            +mob
                height: 112px
                margin-bottom: 32px
            img
                height: 80px
                //filter: brightness(0) invert(1)
                +tab
                    height: 70px
                +mob
                    height: 40px

        &.locked
            z-index: 99
            left: 0
            top: calc(100% - 130px)
            width: 100%
            flex-direction: column
            justify-content: flex-start
            align-items: flex-start
            padding: 40px
            background: $color-brand
            border-radius: 40px
            display: none
            margin-top: -100px
            +mob
                border-radius: 24px
                padding: 24px
            &.active
                display: flex
            &:before
                content: ''
                display: block
                position: absolute
                bottom: 100%
                left: 0
                width: 100%
                height: 80px
                background: linear-gradient(0, #ffffff 0%, #ffffffcc 40%, rgba(255, 255, 255, 0) 100%)
            .title
                display: flex
                align-items: center
                color: $color-white
                margin-bottom: 10px
                +mob
                    line-height: 28px
                img
                    width: 30px
                    margin-right: 10px
            .subtitle
                color: $color-white
                font-size: 20px
                font-weight: 500
                line-height: 32px
                +mob
                    font-size: 14px
                    font-weight: 400
                    line-height: 24px
            .plans
                display: flex
                flex-direction: column
                align-items: flex-start
                padding-left: 0
                padding-right: 0
                background: none
                width: 100%
                .bubble
                    position: absolute
                    width: 420px
                    top: -135px
                    left: 50%
                    transform: translateX(-50%)
                    +tab
                        width: 188px
                        top: -67px
                    +mob
                        width: 188px
                        top: -67px
                    img
                        max-width: 100%
                h3
                    display: block
                    font-size: 32px
                    font-weight: 700
                    line-height: 40px
                    color: #4095f2
                    margin: 0
                    margin-bottom: 20px
                    +mob
                        font-size: 20px
                        font-weight: 700
                        line-height: 28px
                h2
                    margin-bottom: 100px
                    +tab
                        text-align: center
                        max-width: 700px
                        margin-bottom: 40px
                    +mob
                        text-align: center
                        max-width: 350px
                        margin-bottom: 40px
                span
                    font-size: 24px
                    font-weight: 500
                    display: block
                    line-height: 40px
                    margin-top: 16px
                    margin-bottom: 32px
                    &.small
                        font-size: 20px
                        line-height: 32px


                .plan
                    background: $color-light-blue
                    width: 100%
                    height: initial
                    padding: 40px
                    border-radius: 30px
                    display: flex
                    flex-direction: column
                    align-items: flex-start
                    justify-content: flex-start
                    margin-bottom: 30px
                    +tab
                        padding: 30px
                        border-radius: 20px
                        min-width: 460px
                    +mob
                        padding: 24px
                        border-radius: 20px
                    &.border
                        border: 4px solid $color-brand
                        +tab
                            border-width: 3px
                        +mob
                            border-width: 3px
                    .subtitle
                        margin: 0
                        border-top: 1px solid gray
                        width: 100%
                        padding: 15px 0
                    >span
                        margin-top: 16px
                        margin-bottom: 32px
                        //flex-grow: 1
                        +tab
                            margin-bottom: 24px
                        +mob
                            margin-bottom: 24px
                    .main
                        display: flex
                        justify-content: flex-start
                        width: 100%
                        +mob
                            flex-direction: column
                            align-items: flex-start
                        h3:first-child
                            margin-right: 20px
                            width: 50%
                    ul
                        flex-grow: 1
                        li
                            position: relative
                            padding-left: 40px
                            font-size: 20px
                            font-weight: 500
                            line-height: 32px
                            +tab
                                font-size: 16px
                                line-height: 24px
                            +mob
                                font-size: 16px
                                line-height: 24px
                            &:not(:last-child)
                                margin-bottom: 16px
                                +tab
                                    margin-bottom: 8px
                                +mob
                                    margin-bottom: 8px
                            &:before
                                content: ''
                                display: block
                                position: absolute
                                left: 0
                                top: 0
                                width: 32px
                                height: 32px
                                background: url("/assets/icon-check.svg") no-repeat 50% 50% / cover
                                +tab
                                    width: 24px
                                    height: 24px
                                +mob
                                    width: 24px
                                    height: 24px
                            &.cross
                                color: $color-gray
                                &:before
                                    background: url("/assets/icon-cross.svg") no-repeat 50% 50% / cover
                    .price
                        position: relative
                        +mob
                            display: none
                        &-mob
                            display: none
                            margin-bottom: 0
                            margin-top: 20px
                            +mob
                                display: block
                        &.sale
                            text-decoration: line-through
                            font-size: 32px
                            font-weight: 500
                            line-height: normal
                            color: #959595
                            margin-top: 32px
                            margin-bottom: 0
                            +tab
                                margin-top: 24px
                            +mob
                                margin-top: 24px
                                font-size: 20px
                                font-weight: 500
                                line-height: 32px
                            .label
                                position: absolute
                                top: -35%
                                left: 110%
                                background: url(/assets/icon-sale-star.svg) no-repeat 50% 50% / cover
                                height: 60px
                                width: 60px
                                display: flex
                                justify-content: center
                                align-items: center
                                font-size: 16px
                                font-weight: 600
                                color: $color-white
                                z-index: -1
                                +mob
                                    top: -25%
                                    height: 45px
                                    width: 45px
                                    font-size: 12px
        &.top, &.state
            margin-top: 0
            margin-bottom: 40px
            background-color: #FFFFFF
            overflow: visible
            padding: 48px 40px
            display: flex
            align-items: flex-start
            +tab
                margin-top: 0
                margin-bottom: 32px
                padding: 40px
                overflow: initial
                align-items: flex-start
            +mob
                margin-top: 0
                margin-bottom: 32px
                padding: 24px
                overflow: initial
                flex-direction: column-reverse
                align-items: flex-start
                .button.pdf
                    display: none


            .info
                .title
                    +mob
                        display: none
            .scale
                position: relative
                display: none
                width: 100%
                height: 12px
                border-radius: 6px
                overflow: hidden
                background: #F2F2F2
                margin-top: 8px
                margin-bottom: 16px
                .line
                    position: absolute
                    top: 0
                    left: 0
                    height: 100%
                    background: #2AB8AF
                    border-radius: 6px
                +mob
                    display: initial
                &.high
                    .line
                        background: #2AB8AF
                &.norm
                    .line
                        background: #2AB8AF
                &.attention
                    .line
                        background: #FFB82E
                &.low
                    .line
                        background: #F56D6D
            .graph
                position: relative
                width: 328px
                height: 302px
                margin-left: 34px
                flex-shrink: 0
                display: flex
                justify-content: center
                align-items: center
                +tab
                    width: 220px
                    height: 228px
                    margin-left: 28px
                    align-items: flex-start
                +mob
                    margin-left: 0
                    width: 100%
                    height: initial
                    justify-content: flex-start
                svg
                    width: 210px
                    +mob
                        display: none
                img
                    position: absolute
                    width: 90px
                    height: 90px
                    top: 110px
                    left: 50%
                    transform: translateX(-50%)
                    +tab
                        top: 70px
                        width: 64px
                        height: 64px
                    +mob
                        position: relative
                        top: initial
                        left: initial
                        transform: initial
                        width: 32px
                        height: 32px
                        margin-right: 8px
                span
                    position: absolute
                    top: 215px
                    left: 50%
                    font-size: 24px
                    font-weight: 600
                    line-height: 32px
                    transform: translateX(-50%)
                    text-align: center
                    color: #2AB8AF
                    +tab
                        top: 155px
                        font-size: 20px
                        font-weight: 600
                        line-height: 24px
                    +mob
                        position: relative
                        top: initial
                        left: initial
                        transform: initial
                        font-size: 16px
                        font-weight: 700
                        line-height: 24px
                &.high
                    span
                        color: #2AB8AF
                    svg
                        path:last-child
                            stroke: #2AB8AF
                &.norm
                    span
                        color: #2AB8AF
                    svg
                        path:last-child
                            stroke: #2AB8AF
                &.attention
                    span
                        color: #FFB82E
                    svg
                        path:last-child
                            stroke: #FFB82E
                &.low
                    span
                        color: #F56D6D
                    svg
                        path:last-child
                            stroke: #F56D6D


            .title, .titleMob
                color: #4D4D4D
                font-size: 32px
                font-weight: 600
                line-height: 48px
                margin-bottom: 24px
                +tab
                    font-size: 24px
                    font-weight: 600
                    line-height: 32px
                    max-width: 100%
                +mob
                    font-size: 20px
                    font-weight: 700
                    line-height: 32px
                    margin-bottom: 16px
                    max-width: 100%
            .titleMob
                display: none
                +mob
                    display: initial
            .descr
                color: #2A2A2B
                font-size: 20px
                font-weight: 300
                line-height: 32px
                +tab
                    font-size: 16px
                    font-weight: 400
                    line-height: 24px
                    max-width: 100%
                +mob
                    font-size: 14px
                    font-weight: 400
                    line-height: 24px
                    max-width: 100%
            .button
                +mob
                    font-size: 16px
                    padding: 0 24px

        &.pdf-results
            display: none
            padding: 0
            border-radius: 24px
            .button
                margin: 0
                color: #4095F2
                font-size: 16px
                font-weight: 400
                line-height: 24px
                justify-content: space-between
                height: auto
                padding: 20px 24px
            +mob
                display: block
        &.recs
            margin-bottom: 0
            border-bottom-right-radius: 0
            border-bottom-left-radius: 0
            .title
                margin-bottom: 32px
                +tab
                    margin-bottom: 24px
                +mob
                    margin-bottom: 16px
            .panels
                display: flex
                column-gap: 20px
                +tab
                    column-gap: 8px
                +mob
                    flex-direction: column
                    column-gap: initial
                    row-gap: 16px
                .panel
                    width: 50%
                    border: 2px solid #F1EDE9
                    border-radius: 30px
                    padding: 32px 32px 40px 32px
                    +tab
                        border-width: 1px
                        padding: 40px 24px
                    +mob
                        border-width: 1px
                        padding: 24px 20px
                        width: 100%
                    .trigger
                        margin-left: 0
                        margin-bottom: 24px
                        +mob
                            margin-bottom: 16px
                        img
                            width: 24px
                            height: 24px
                            +mob
                                width: 16px
                                height: 16px
                    .descr
                        font-size: 20px
                        font-weight: 300
                        line-height: 32px
                        +tab
                            font-size: 16px
                            line-height: 24px
                        +mob
                            font-size: 16px
                            font-weight: 400
                            line-height: 24px

        &.promo
            display: flex
            column-gap: 30px
            background: #E2F0FF
            border-top-left-radius: 0
            border-top-right-radius: 0
            +mob
                flex-direction: column-reverse
                background: #FFFFFF
            .left
                position: relative
                display: flex
                flex-direction: column
                justify-content: center
                .title
                    margin-bottom: 24px
                    +mob
                        font-size: 16px
                        font-weight: 700
                        line-height: 24px
                        margin-bottom: 16px
                .descr
                    font-size: 20px
                    font-weight: 300
                    line-height: 32px
                    +tab
                        font-size: 16px
                        line-height: 24px
                    +mob
                        font-size: 16px
                        font-weight: 500
                        line-height: 20px
            .right
                position: relative
                margin-top: -40px
                margin-bottom: -40px
                +mob
                    background: #F2F8FF
                    margin: -24px -24px 24px -24px
                .u, .u_mob
                    position: relative
                    height: 440px
                    z-index: 1
                    +tab
                        height: 338px
                    +mob
                        width: 100%
                        height: initial
                        z-index: 0
                .u
                    +mob
                        display: none
                .u_mob
                    display: none
                    +mob
                        display: initial
                        z-index: 11
                .doc, .doc_mob
                    position: absolute
                    max-width: 100%
                    max-height: 105%
                    min-height: 105%
                    left: 50%
                    bottom: 0
                    transform: translateX(-50%)
                    +mob
                        z-index: 1
                        height: 95%
                .doc
                    +mob
                        display: none
                .doc_mob
                    display: none
                    +mob
                        display: initial



    .results
        .result
            display: flex
            justify-content: space-between
            align-items: center
            cursor: pointer
            padding: 40px
            transition: box-shadow $transitionFast
            &:hover
                box-shadow: 0 0 15px rgb(5 6 6 / 9%)
            &:not(:last-child)
                margin-bottom: 32px
                +tab
                    margin-bottom: 24px
                +mob
                    margin-bottom: 16px
            +tab
                padding: 32px 40px
            +mob
                flex-direction: column
                position: relative
                justify-content: flex-start
                align-items: flex-start
                height: auto
                padding: 32px 24px
            .left
                display: flex
                justify-content: flex-start
                align-items: center
                +mob
                    width: 100%
                .trigger
                    flex-shrink: 0
                    &-icon
                        margin-left: 0
                        margin-right: 24px
                        +mob
                            margin-right: 16px
                            min-width: 32px
                            min-height: 32px
                    &-mobile
                        display: none
                        margin-left: auto
                        min-width: 32px
                        img
                            transform: rotateX(180deg)
                            transition: $transitionNorm transform
                        +mob
                            display: flex
                .title
                    margin-bottom: initial
                    font-size: 24px
                    font-weight: 700
                    line-height: 48px
                    color: #4095F2
                    +tab
                        line-height: 28px
                        padding-right: 45px
                    +mob
                        font-size: 16px
                        line-height: 24px
                        padding-right: 15px
            .right
                display: flex
                justify-content: flex-end
                align-items: center
                +mob
                    width: 100%
                    margin-top: 16px
                .bar
                    width: 288px
                    display: flex
                    flex-direction: column
                    justify-content: flex-start
                    +tab
                        width: 250px
                    +mob
                        width: 100%
                    .info
                        display: flex
                        justify-content: space-between
                        align-items: center
                        margin-bottom: 8px
                        .tag
                            font-size: 16px
                            font-weight: 400
                            line-height: 24px
                            color: #757575
                    .scale
                        position: relative
                        width: 100%
                        height: 16px
                        border-radius: 6px
                        background: #F2F2F2
                        overflow: hidden
                        +mob
                            height: 12px
                        .line
                            position: absolute
                            top: 0
                            left: 0
                            height: 100%
                            background: #2AB8AF
                            border-radius: 6px

                    &.high
                        .scale
                            .line
                                background: #2AB8AF
                    &.norm
                        .scale
                            .line
                                background: #2AB8AF
                    &.attention
                        .scale
                            .line
                                background: #FFB82E
                    &.low
                        .scale
                            .line
                                background: #F56D6D





